@charset "utf-8";
/*
//=============================================================
//　Header,Global nav
//=============================================================
*/
// header｜ヘッダー
// gnav｜左ナビゲーション
// home｜ホームに戻る

//-------------------------------------------------------------
// header｜ヘッダー
//-------------------------------------------------------------
.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background: $main_color;
    box-shadow: 0 2px 6px rgba(0,0,0,.16);
    z-index: 99999999;
    &.is-mypage,&.is-nobg {
        background: #fff;
    }
}
// マップのタイトル
//-------------------------------------------------------
.header-title {
    position: absolute;
    left: 70px;
    top: 0;
    height: 60px;
    width: calc(100% - 210px);
    background: $main_color;
    z-index: 9999;
    display: flex;
    align-items: center;
    line-height: 1.2;
    &.is-wide {
        width: calc(100% - 130px);
    }
    &.is-max {
        width: calc(100% - 80px);
    }
    &.is-map {
        background: none;
        width: 50%;
    }
    &.is-only {
        background: #fff;
        width: 100%;
    }
    h1 {
        font-size: fz(20);
        margin: 0 .5em;
    }
    @include mq-down(sm) {
        h1 {
            font-size: fz(13);
        }
    }
}

// 左ナビアイコン（メニュー）
//-------------------------------------------------------------
.gnav-toggle {
    border-right: 1px solid darken($main_color,10%);
    background-color: $main_color;
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 60px;
    cursor: pointer;
    z-index: 300;
    transition: ease .25s;
    &:hover {
        background: darken($main_color,5%);
    }
    .is-mypage & {
        background: #1a1a1a;
        border: none;
    }
    @include mq-down(sm) {
        width: 60px;
    }
}
.gnav-toggle-line {
    position: absolute;
    left: 24px;
    width: 22px;
    height: 2px;
    background-color: $font_color;
    transition: all .6s;
    &--1 {
        top: 14px;
    }
    &--2 {
        top: 20px;
    }
    &--3 {
        top: 26px;
    }
    .is-mypage & {
        background-color: #fff;
    }
    @include mq-down(sm) {
        left: 19px;
    }
}
.gnav-txt {
    position: absolute;
    bottom: 9px;
    left: 0;
    color: $font_color;
    font-size: fz(10);
    font-weight: 500;
    line-height: 1;
    width: 100%;
    text-align: center;
    .is-mypage & {
        color: #fff;
    }
    @include mq-down(sm) {
        font-size: fz(8);
    }
}
//　ナビ開口時（メニュー）
//-------------------------------------------------------------
.nav-open {
    .gnav {
        left: 0;
    }
    .gnav-toggle {
        background-color: #101111;
    }
    .is-mypage .gnav-toggle {
        background: #f4c50a;
        border: none;
    }
    .gnav-toggle-line {
        background: #fff;
        &--1 {
        transform: rotate(45deg);
        top: 20px;
        }
        &--2 {
        width: 0;
        left: 50%;
        }
        &--3 {
        transform: rotate(-45deg);
        top: 20px;
        }
    }
    .gnav-txt {
        color: #fff;
    }
}
//　印刷
//-------------------------------------------------------------
.h-map {
    position: absolute;
    top: 12px;
    right: 0;
    &-print {
        display: flex;
        align-items: center;
        li {
            padding-right: 8px;
        }
    }
}


//　左ナビアイコン（戻る矢印）スポット登録時
//-------------------------------------------------------------
.h-back {
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
    width: 70px;
    //border-right: 1px solid darken($main_color,10%);
    cursor: pointer;
    line-height: 1;
    background: #1a1a1a;
    &:hover {
        background: lighten(#1a1a1a,10%);
    }
    &-inner {
        color: #111;
        line-height: 1;
        padding: .5em 0 0;
    }
    a {
        text-decoration: none;
        display: block;
        position: relative;
        height: 60px;
    }
    @include mq-down(sm) {
        width: 60px;
    }
}
.h-back-icon,.h-back-txt {
    display: block;
    text-align: center;
}
.h-back-icon {
    display: block;
    position: relative;
    &::after {
        position: absolute;
        content: '';
        background: url(#{$img_path}icon_back-w.png) no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.h-back-txt {
    color: #fff;
    font-size: fz(10);
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    text-align: center;
}
//-------------------------------------------------------------
// gnav｜左ナビゲーション
//-------------------------------------------------------------
.gnav {
    position: fixed;
    left: -350px;
    top: 60px;
    width: 300px;
    height: 100vh;
    padding: 0 0 0;
    background-color: #fff;
    transition: all .6s;
    z-index: 200;
    overflow-y: auto;
    background: #2e2e2e;
    margin: 0;
    .is-mypage & {
        background: #171717;
    }
    @include mq-down(md) {
        width: 220px;
    }
    &-sub-ttl {
        color: #808080;
        font-size: fz(11);
    }
    .is-mypage & {
        width: 300px;
    }
}
.gnav-layer {
    padding: 0 0 1em 20px;
    li {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background: $gray;
            top: 40%;
            left: -1em;
        }
    }
    a {
        &:link,&:visited {
            color: $gray;
            text-decoration: none;
        }
        &:hover {
            color: $main_color;
        }
        &.is-active {
            color: $main_color;
        }
    }
}

.gnav-inner {
    padding: 1em 1em 15em;
}
// マイページメニュー
.gnav-mypage {
    padding: 0;
    li {
        position: relative;
        border-bottom: 1px solid #2b2b2b;
        &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background: $gray;
            top: 40%;
            left: -1em;
        }
    }
    a {
        display: block;
        line-height: 1.4;
        padding: .8em 2em .8em 1em;
        position: relative;
        color: white;
        text-decoration: none;
        &:link {
            color: $gray;
        }
        &:visited {
            color: $gray;
        }
        &:hover {
            color: #ffde02 !important;
            cursor: pointer;
        }
        &.is-active {
            color: #ffde02 !important;
        }
        &::after {
            position: absolute;
            content: '';
            width: 6px;
            height: 6px;
            border-top: 1px solid $main_color;
            border-right: 1px solid $main_color;
            transform: rotate(45deg);
            top: 41%;
            right: .8em;
        }
    }
}
//　アコーディオン｜マップナビゲーション
//-------------------------------------------------------------
.gnav-list-ac {
    position: relative;
    &.is-line {
        border-bottom: 1px solid lighten(#000,30%);
        padding-bottom: 1em;
        margin-bottom: 1em;
    }
    p {
        position: relative;
        color: $gray;
        display: block;
    }
    ul {
        display: none;
        font-size: fz(12);
        padding: 0 0 0 15px;
    }
    @include mq-down(sm){
        >.f-check {
            font-size: fz(12);
        }
        >ul {
            font-size: fz(11);
        }
    }
}
.ac-item {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    &::before {
        background: #fff;
        position: absolute;
        content: "";
        height: 2px;
        right: 0;
        top: 11px;
        transform: rotate(90deg);
        transition: all .2s ease-in-out;
        width: 14px;
    }
    &::after {
        background: #fff;
        content: "";
        height: 2px;
        position: absolute;
        right: 0;
        top: 11px;
        width: 14px;
    }
    &:hover {
        cursor: pointer;
    }
    &.is-active::before {
        transform: rotate(0);
    }
}
.ac-hanrei {
    &-item {
        display: flex;
        align-items: center;
        color: #e5e5e5;
        line-height: 1;
        margin-bottom: .35em;
        .icon-marker {
            display: inline-block;
            width: 25px;
            margin-right: 5px;
        }
        .icon-name {
            color: #8c8c8c;
            line-height: 1;
            width: calc(100% - 30px);
        }
    }
}
.icon-no {
    height: 14px;
    margin-top: 3px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 1;
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;

    }
    &.h-kouzui-lv1::after,&.h-takashio-lv1::after,&.h-tsunami-lv1::after {
        background: #F7EFC0;
    }
    &.h-kouzui-lv2::after,&.h-takashio-lv2::after,&.h-tsunami-lv2::after {
        background: #FFD8C0;
    }
    &.h-kouzui-lv3::after,&.h-takashio-lv3::after,&.h-tsunami-lv3::after {
        background: #FFB7B7;
    }
    &.h-kouzui-lv4::after,&.h-takashio-lv4::after,&.h-tsunami-lv4::after {
        background: #FF9191;
    }
    &.h-kouzui-lv5::after,&.h-takashio-lv5::after,&.h-tsunami-lv5::after {
        background: #F285C9;
    }
    &.h-kouzui-lv6::after,&.h-takashio-lv6::after,&.h-tsunami-lv6::after {
        background: #DC7ADC;
    }
    // 急傾斜地
    &.h-keisha-lv1::after {
        background: #FBE919;
    }
    &.h-keisha-lv2::after {
        background: #FB5D0E;
    }
    // 急傾斜地
    &.h-dosekiryu-lv1::after {
        background: #E3CD3D;
    }
    &.h-dosekiryu-lv2::after {
        background: #AA1629;
    }
    // 地滑り
    &.h-jisuberi-lv1::after {
        background: #FDA00E;
    }
    &.h-jisuberi-lv2::after {
        background: #B8106E;
    }

}
// 登録したスポット
//-------------------------------------------------------------
// スポットアイコン
.icon-marker {
    display: inline-block;
    width: 25px;
    margin-right: 5px;
}
// スポット登録ボタン
.gnav-spot-btn {
    border-bottom: 1px solid #4d4d4d;
    padding-bottom: 1.5em;
    margin: 1em 0;
}
// 凡例
.icon-no {
    
}
//-------------------------------------------------------------
// 3.subnav｜設定ナビゲーション
//-------------------------------------------------------------
.h-setting {
    position: absolute;
    right: 70px;
    top: 0;
    height: 60px;
    width: 70px;
    border-left: 1px solid darken($main_color,10%);
    cursor: pointer;
    &-inner {
        color: #111;
        line-height: 1;
        padding: 0;
        position: relative;
        height: 60px;
        .is-mypage & {
            color: #fff;
        }
    }
    @include mq-down(sm) {
        width: 60px;
        right: 60px;
    }
}
.h-set {
    display: block;
    position: relative;
    &::after {
        position: absolute;
        content: '';
        background: url(#{$img_path}icon_map.png) no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.h-set-txt {
    font-size: fz(10);
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    text-align: center;
    @include mq-down(sm) {
        font-size: fz(8);
    }
}
// 設定ナビが開いた時　アイコン変更等
//-------------------------------------------------------------
.setting-open .h-setting-inner {
    background: darken($main_color,5%);
}
// 設定メニューを表示
.setting-open .h-subnav {
    right: 0;
}
.h-subnav {
    position: fixed;
    right: -200px;
    top: 60px;
    width: 185px;
    transition: all .6s;
}
.h-setting-list {
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    li {
        border-bottom: 1px solid #f1f1f1;
        white-space: nowrap;
        a {
            color: #555;
            display: block;
            font-size: fz(12);
            padding: 1em 2em 1em .8em;
            position: relative;
            z-index: 9999;
            //background: #2e2e2e;
            text-decoration: none;
            &:hover {
                background: #f1f1f1;
            }
            &:after {
                content: '';
                margin: auto;
                position: absolute;
                top: 45%;
                right: 15px;
                width: 7px;
                height: 7px;
                border-top: 1px solid #000;
                border-right: 1px solid #000;
                transform: rotate(45deg);
            }
        }
    }
    @include mq-down(sm){
        li:first-child {
            display: none;
        }
    }
}
.h-sl-item {
    display: flex;
    align-items: center;
    dt {
        display: inline-block;
        width: 20px;
        height: 20px;
        //background: url(#{$img_path}icon_print.png) no-repeat center center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        margin-right: 6px;
        &.i-map {
            background-image: url(#{$img_path}icon_print.png);
        }
        &.i-qr {
            background-image: url(#{$img_path}icon_qr.png);
        }
        &.i-pin {
            background-image: url(#{$img_path}icon_pin.png);
        }
    }
}
//-------------------------------------------------------------
// home｜ホームに戻る
//-------------------------------------------------------------
.h-home {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    width: 70px;
    border-left: 1px solid darken($main_color,10%);
    cursor: pointer;
    transition: ease .25s;
    &:hover {
        background: darken($main_color,5%);
    }
    .is-mypage  & {
        border-left: 1px solid #e5e5e5;
        &:hover {
            background: #e5e5e5;
        }
    }
    &-inner {
        color: #111;
        line-height: 1;
        padding: 0;
        .is-mypage & {
            color: #fff;
        }
    }
    a {
        display: block;
        text-decoration: none;
        position: relative;
        height: 60px;
    }
    @include mq-down(sm) {
        width: 60px;
    }
}
.h-home-icon,.h-home-txt {
    display: block;
    text-align: center;
}
.h-home-icon {
    display: block;
    position: relative;
    &::after {
        position: absolute;
        content: '';
        background: url(#{$img_path}icon_home.png) no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.h-home-txt {
    font-size: fz(10);
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    text-align: center;
    @include mq-down(sm) {
        font-size: fz(8)
    }
}
//-------------------------------------------------------------
// user｜ユーザー名
//-------------------------------------------------------------
.h-user {
    position: absolute;
    left: 70px;
    top: 0;
    height: 60px;
    width: calc(100% - 140px);
    cursor: pointer;
    transition: ease .25s;
    &-inner {
        color: #111;
        padding: 0;
        .is-mypage & {
            color: #fff;
        }
    }
    a {
        display: flex;
        height: 60px;
        line-height: 1.3;
        text-decoration: none;
        text-align: right;
        padding: 0 1em 0 0;
        align-items: center;
        justify-content: center;
        span {
            font-size: fz(12);
        }
    }
    @include mq-down(sm) {
        a {
            span {
                font-size: fz(11);
            }
        }
    }
}
//-------------------------------------------------------------
// footer｜フッター
//-------------------------------------------------------------
footer {
    background: #111;
    color: #fff !important;
    padding: 6em 1em 4em;
    a:link,a:visited {
            color: #fff;
            text-decoration: none;
    }
    a:hover {
        color: #ffde02 !important;
    }
    .footer-logo a{
        display: block;
        width: 160px;
        text-align: center;
    }
    @include mq-down(md) {
        padding: 2.5em 1em;
        .footer-logo {
            display: flex;
            justify-content: flex-end;
            a {
                width: 110px;
            }
        }
    }
}
.footer-contact {
    dt {
        font-size: fz(12);
    }
    a {
        color: #fff;
        display: block;
        font-size: fz(11);
        position: relative;
        padding: 0 0 0 1.2em;
        transition: .2s;
        &::before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #666;
            border-right: 2px solid #666;
            transform: rotate(45deg);
            top: 11px;
            left: 0;
        }
    }
}
.footer-inner {
  margin: 0 auto;
  max-width: 1200px;
  min-width: 800px;
  width: 84%;
    display: flex;
    justify-content: space-between;
    &__l {
    }
    &__r {
    }
    @include mq-down(md) {
        display: block;
        min-width: 100%;
        max-width: 100%;
        &__l {
            padding-bottom: 2em;
        }
        &__r {
        }
    }
}
.footer-nav {
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 3em;
    li {
        padding: 0 2em 0 0;
        width: 50%;
        &:first-child {
            display: none;
        }
    }
    a {
        color: white !important;
    }
    a:hover {
        color: #ffde02 !important;
        cursor: pointer;
    }
    .link-tb {
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            background: url(#{$img_path}icon_link-w.png) no-repeat;
            background-size: cover;
            top: 7px;
            right: -1.2em;
        }
    }
    @include mq-down(sm) {
        display: block;
        li {
            width: 100%;
        }
        li:first-child {
            display: block;
            color: $gray;
            font-size: fz(10);
        }
        a {
            display: block;
            padding: .5em;
        }
        .link-tb {
            &::after {
                top: 18px;
            }
        }
    }
}
.footer-subnav {
    padding-bottom: 3em;
    font-size: fz(11);
    a {
        color: white !important;
    }
    a:hover {
        color: #ffde02 !important;
        cursor: pointer;
    }
    @include mq-down(sm) {
        padding-bottom: 1em;
        a {
            display: block;
            padding: .5em 0;
        }
    }
}