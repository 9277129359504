@charset "utf-8";
/*
//=============================================================
//　Main Style
//=============================================================
*/
// Home
// Hanrei,Spot      - 凡例とスポット
// Common     	    - 共通
// Modal            - モーダル
// Page top       	- ページの先頭にもどる
// Table            - テーブル
// Parts            - 各パーツ

body {
  background: $gray2;
}
//　Links
a {
	color: $link_color;
	&:visited {
		color: $link_color_visited;
	}
	&:hover {
		text-decoration: none;
		color: $link_color_hover;
	}
	&:active {
		color: $link_color_active;
	}
}
img {
  height: auto;
  max-width: 100%;
  width: auto\9;
}
//-------------------------------------------------------------
// Map         		- マップ表示エリア
//-------------------------------------------------------------
#app {
	height: 100%;
	width: 100%;
	margin-top: -50px;
}
#map {
	position: absolute;
	z-index: 0;
	height: 100%;
	width: 100%;
	a {
		text-decoration: none;
	}
}
.main {
	margin-top: 50px;
	&.is-no-layers-icon {
		.leaflet-touch .leaflet-control-layers-toggle {
			display: none;
		}
	}
}
.main-inner {
	padding-left: 0;
	margin-top: 50px;
}
// マップ上のボタン
.l-map-btn {
	position: absolute;
	top: 70px;

	z-index: 999;
	background: #fff;
	border: 2px solid rgba(#000,0.2);
	border-radius: 5px;
	background-clip: padding-box;
	a {
		display: block;
		width: 44px;
		height: 44px;	
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 34px;
			height: 34px;
			background: url(#{$img_path}icon_shinsui.png) no-repeat;
			background-size: cover;
		}
	}
	&--reload {
		right: 65px;
		a::before {
			background: url(#{$img_path}icon_reload.png) no-repeat;
			background-size: cover;
		}		
	}
	&--here {
		right: 121px;
		a::before {
			background: url(#{$img_path}icon_here.png) no-repeat;
			background-size: cover;
		}		
	}
	&--search {
		right: 177px;
		a::before {
			background: url(#{$img_path}icon_search.png) no-repeat;
			background-size: cover;
		}
	}
	@include mq-down(sm){
		a {
			width: 34px;
			height: 34px;
			&::before {
				width: 24px;
				height: 24px;
			}			
		}
		&--reload {
			right: 47px;
		}
		&--here {
			right: 89px;
		}
		&--search {
			right: 131px;
		}
	}
}
// レイヤーアイコンの位置
.leaflet-control-layers {
	left: 54px!important;
	top: 114px!important;
	@include mq-down(sm){
		left: 59px!important;
		top: 102px!important;
	}
	@include mq-down(sm){
		.leaflet-control-layers-toggle {
			width: 34px;
			height: 34px;
		}
	}
}
.is-first-layers-icon {
	.leaflet-control-layers {
		left: -114px !important;
		top: 60px !important;
	}
	.l-map-btn--reload {
		right: 10px;
	}
	.l-map-btn--here {
		right: 66px;
	}
	.l-map-btn--search {
		right: 122px;
	}
	@include mq-down(sm){ 
		.leaflet-control-layers {
			left: -75px !important;
			top: 60px !important;
		}
		.leaflet-touch .leaflet-control-layers-toggle {
			width: 34px;
			height: 34px;
		}
		.l-map-btn--here {
			right: 53px;
		}
		.l-map-btn--search {
			right: 96px;
		}
	}
}
.leaflet-popup-content-wrapper {
	width: 400px !important;
	@include mq-down(sm){
		width: 90vw !important;
	}
}
.leaflet-container a.leaflet-popup-close-button {
	@include mq-down(sm){
		width: 30px !important;
		height: 30px !important;
		font-size: 18px !important;
		line-height: 18px !important;
	}
}
// 描画時の保存ボタン
.footer-btn {
	position: fixed;
	left: 50%;
	bottom: 0;
	width: 140px;
	height: 70px;
	background: #fff;
	box-shadow: 0 0 6px rgba(0,0,0,.16);
	border-radius: 10px 10px 0 0;
	transform: translateX(-50%);
	z-index: 9999;
	&-save {
		text-align: center;
		padding: 15px;
	}
	@include mq-down(sm){
		left: 50%;
		bottom: 0;
		width: 170px;
		height: auto;
		background: #fff;
		border-radius: 10px 10px 0 0;
		transform: translateX(-50%);
		&-save {
			padding: 15px;
		}
	}
}
// popupの枠
#map {
	.leaflet-popup-content {
		@include mq-down(sm){
			margin: 0 !important;
		}
	}
}
//.footer-btn {
//	position: fixed;
//	left: 0;
//	bottom: 0;
//	width: 100%;
//	height: 60px;
//	background: rgba(255,255,255,.5);
//	box-shadow: 0 0 6px rgba(0,0,0,.16);
//	z-index: 9999;
//	&-save {
//		text-align: center;
//		padding: 10px 2em;
//	}
//	@include mq-down(sm){
//		left: 50%;
//		bottom: 0;
//		width: 170px;
//		height: auto;
//		background: #fff;
//		border-radius: 10px 10px 0 0;
//		transform: translateX(-50%);
//		&-save {
//			padding: 15px;
//		}
//	}
//}
//-------------------------------------------------------------
// Home
//-------------------------------------------------------------
.top-container,.low-container {
  box-sizing: border-box;
	background: #fff;
  width: $base_wrap;
	padding: 5%;
	position: relative;
	z-index: 2;
	height: 100vh;
	margin-top: 50px;
	&::before {
		content: '';
		position: absolute;
		width: 30%;
		height: 100%;
		top: 0;
		left: 0;
		background: url(#{$img_path}top_bg.jpg) left top;
		background-size: cover;
		z-index: 1;
	}
	&.is-login {
		height: auto;
	}
	&.is-mypage {
		background: #e8e8e8;
		height: 85vh;
		margin-top: 110px;
	}
	@include mq-down(md){
		height: auto;
		&.is-mypage {
			height: auto;
		}
	}
}
.low-container {
	height: auto;
	background: #e8e8e8;
	margin-top: 110px;
	@include mq-down(sm){
		padding: 0;
	}
}
.top-contents {
	position: relative;
	background: url(#{$img_path}map_bg.jpg) no-repeat center center;
	background-size: cover;
	padding: 10% 7%;
	z-index: 10;
	height: 100%;
	&.is-login {
		height: auto;
		padding-top: 5em;
		padding-bottom: 4em;
	}
	@include mq-down(sm){
		&.is-login {
			padding-top: 3em;
			padding-bottom: 3em;
		}
		padding: 2em 1.5em .3em;
	}
}
.low-contents {
  background: #fff;
  margin: 0 auto;
  max-width: $base_width;
  min-width: 959px;
  width: 84%;
	position: relative;
	z-index: 10;
  &::after {
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
  }
	&-inner {
		padding: 3em 5em;
	}
  @include mq-down(md) {
    max-width: $base_wrap;
    min-width: $base_wrap;
    padding: 0 3%;
		&.is-timeline {
			padding: 0;
		}		
  }
	@include mq-down(sm) {
    padding: 0 2%;
		&-inner {
			padding: .5em .5em 2em;
		}
	}
}
.top-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	>li {
		width: 50%;
		text-align: center;
	}
	>li:last-child {
		width: 40%;
	}
	h2 {
		color: $main_color;
		font-size: fz(16);
		letter-spacing: .1em;
		text-align: left;
		padding-bottom: .8em;
	}
	@include mq-down(md){
		display: block;
		>li,>li:last-child {
			width: auto;
			h2 {
				text-align: center;
			}
		}
		>li {
			margin-bottom: 2em;
		}
	}
}
.main-title {
	text-align: center;
	margin: 0 auto;
	img {
		max-width: 476px;
		width: 70%;
	}
	@include mq-down(md){
		max-width: 60vw;
	}
	@include mq-down(sm){
		max-width: 70vw;
	}
}
.map-btn {
	text-align: left;
	li {
		margin: 0 0 1em;
		@include mq-down(md){
			width: 100%;
		}
	}
}
// トップページ-データから見えるもの・データカタログ
.de-contents {
	display: flex;
	justify-content: space-between;
	li {
		width: 49%;
		background: #fff;
	}
	a {
		display: flex;
		text-decoration: none;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			width: 20px;
			height: 20px;
			background: url(#{$img_path}icon_link.png) no-repeat;
			background-size: cover;
			bottom: 1em;
			right: 1em;
		}
		div {
			width:40%;
			background: $main_color;
			position: relative;
			padding: 9em 0;
			&::before {
				content: '';
				position: absolute;
				width: 160px;
				height: 88px;
				background-repeat: no-repeat;
				background-size: cover;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
			&.dashboard::before {
				background-image: url(#{$img_path}dashboard.png);
			}
			&.datacatalog::before {
				background-image: url(#{$img_path}datacatalog.png);
			}
		}
	}	
	.dec-in {
		width: 60%;
		padding: 2em;
		dt {
			font-size: fz(15);
			font-weight: 500;
			padding-bottom: 1em;			
		}
		dd {
			color: #888;
			font-size: fz(12);
			line-height: 1.55;
			letter-spacing: .08em;		
		}
	}
	@include mq-down(md){
		flex-direction: column;
		li {
			width: 100%;
			&:first-child {
				margin-bottom: 1em;
			}
		}
		a {
			flex-direction: column;
			div {
				display: block;
				height: 130px;
				width: 100%;
				padding: 0;
			}
			.dec-in {
				width: 100%;
				padding: 2em 1.6em 4em 1.6em;
			}
		}
	}
}
//-------------------------------------------------------------
// Login      - ログインフォーム
//-------------------------------------------------------------
.login-form {
	width: 100%;
	max-width: 800px;
	min-width: 700px;
	margin: 0 auto;
	h1 {
		text-align: center;
		margin: 0 auto 2em;
		img {
			max-width: 280px;
			width: 70%;
		}
	}
	h2 {
		color: $main_color;
		font-size: fz(15);
		text-align: center;
		margin-bottom: 1em;
	}
	@include mq-down(md){
		max-width: auto;
		min-width: auto;
		h1 {
			max-width: 80vw;
		}
	}
}
.top-login {
	background: rgba(#fff,.9);
	border-radius: 8px;
	padding: 3em;
	margin: 0 5em 3em;
	@include mq-down(sm){
		margin: 0;
		padding: 1em 1em 2em;
	}
}
//-------------------------------------------------------------
// Spot regist      - スポットの登録
//-------------------------------------------------------------
.spot-regist {
	background: rgba(#fff, 0.9);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 75px;
	padding: 0;
	width: 350px;
	left: 1%;
	z-index: 99;
	&-inner {
		padding: .8em 1em;
	}
	@include mq-down(sm){
		top: auto;
		bottom: 0;
		width: 98%;
		&-inner {
			padding: .5em;
			border-top: 0;
			border-bottom: 1px solid #ccc;
		}
	}
}
.spot-regist-pre {
	background: rgba(#fff, 0.9);
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 0;
	width: 340px;
	z-index: 99;
	transform: translate(-50%,-50%);
	&-inner {
		padding: 2em;
	}
	@include mq-down(sm){
		width: 96%;
	}
}
.spot-step {
	display: flex;
	padding: 0 0 .5em;
	&-num {
		display: inline-block;
		padding: 0 .6em;
		background: #3474e0;
		border-radius: 20px;
		color: #fff;
	}
	&-cmt {
		font-size: fz(11);
	}
	dt {
		padding-right: .6em;
	}
	dd {
		color: #555;
		font-size: fz(12);
	}
	@include mq-down(sm){
		line-height: 1.25;
		font-size: fz(11);
	}
}
.spot-slider {
	overflow-x: auto;
	margin-bottom: .5em;
}
.spot-regist-icon-list {
	float: left;
	padding: 0 0 5px;
	width: 2500px;
		&::after {
		 content: "";
		 display: block;
		 clear: both;
	}
	li {
		display: inline-block;
	}
	@include mq-down(sm){
		width: 2550px;
	}
}
.spot-regist-btn {
	display: flex;
	background: #e5e5e5;
	border-radius: 5px;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.34);
	align-items: center;
	margin: 2px !important;
	padding: 5px 8px;
	margin-right: 3px;	
	&:hover {
		background: #dbdbdb;
	}
	&_icon {
		display: inline-block;
		width: 30px;
		margin: 0 auto;
		padding: 0 5px 0 0;
	}
	&_text {
		font-size: fz(12);
		display: block;
		line-height: 1.3;
	}
}
.spot-save-btn {
	@include mq-down(sm) {
		margin: 0 auto;
		text-align: center;	
	}
}
//-------------------------------------------------------------
// Hanrei,Spot      - 凡例とスポット
//-------------------------------------------------------------
// 凡例
//-------------------------------------------------------------
.hanrei-shinsui {
	position: absolute;
	top: 70px;
	right: 10px;
	z-index: 999;
	background: #fff;
	border: 2px solid rgba(#000,0.2);
	border-radius: 5px;
	background-clip: padding-box;
	a {
		display: block;
		width: 44px;
		height: 44px;	
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 34px;
			height: 34px;
			background: url(#{$img_path}icon_shinsui.png) no-repeat;
			background-size: cover;
		}
	}
	@include mq-down(sm) {
		right: 5px;
		a {
			width: 34px;
			height: 34px;
			&::before {
				width: 24px;
				height: 24px;
			}
		}
	}
}
.hanrei {
	padding: .5em;
	position: absolute;
	bottom: 15px;
	left: 10px;
	line-height: 1.45;
	background: rgba(#fff, 0.9);
	z-index: 999;
	transition: all .6s;
	max-width: calc(100% - 360px);
	.nav-open & {
		left: 310px;
	}
	@include mq-down(sm) {
		display: none;
	}
}
.hanrei-list {
	display: flex;
	flex-wrap: wrap;
	li {
		display: inline-block;
		font-size: fz(11);
		line-height: 1.45;
		padding-right: 1em;
		padding-bottom: .15em;
	}
	span {
		display: inline-block;
		height: 25px;
		width: 25px;
		margin-right: .5em;
	}
	dl {
		display: flex;
		align-items: center;
	}
	.l-circle {
		border-radius: 15px;
		width: 16px;
		height: 16px;
	}
}
//　スポットリストは不要？左ナビの中
.spot-list {
	display: flex;
	li {
		display: flex;
		align-items: center;
		font-size: fz(11);
		line-height: 1.45;
		padding-right: 1em;
	}
	span {
		display: inline-block;
		height: 1em;
		width: 1em;
		margin-right: .5em;
	}
	.l-circle {
		border-radius: 15px;
		width: 16px;
		height: 16px;
	}
}
/*
// 凡例  避難所周辺施設
//-------------------------------------------------------------
*/
//.color {
//	&-keikai {
//		background: #F0EC3F;
//	}
//	&-keikai-s {
//		background: #D84135;
//	}
//	&-deep-purple {background: #622082;}
//	&-violet {background: #9932cc;}
//	&-carmine {background: #d7003a;}
//	&-orange {background: #ee7800;}
//	&-golden-yellow {background: #f8b856;}
//	&-canary-yellow {background: #fff262;}
//	&-green {background: #008000;}
//	&-meadow-green {background: #65ab31;}
//	&-green-yellow {background: #adff2f;}
//	&-blue {background: #3e63f5;}
//	&-cyan {background: #4194f6;}
//	&-sky-blue {background: #5cc6f8;}
//	&-baby-blue {background: #8ffcfd;}
//	&-alice-blue {background: #d0fefd;}
//	&-gray {background: #888;}
//}
/*
// 避難所周辺施設
//-------------------------------------------------------------
*/
//.color {
//	&-gas {/* ガソリンスタンド */background: #b27ce9;}
//	&-car-rental {/* レンタカー */background: #841cee;}
//	&-bank {/* 銀行 */background: #11ed92;}
//	&-hotel {/* 宿泊施設 */background: #6e9459;}
//	&-station {/* 駅・バス停 */background: #07bb24;}
//	&-laundry {/* コインランドリー */background: #50f1ff;}
//	&-convenience {/* コンビニ */background: #007f15;}
//	&-supermarket {/* スーパーマーケット */background: #f25a07;}
//	&-restaurant {/* 飲食店 */background: #ff9600;}
//	&-drag-store {/* ドラッグストア */background: #ffcc00;}
//	&-home-center {/* ホームセンター */background: #a1553e;}
//	&-clothing-store {/* 衣料品店 */background: #3f5aff;}
//	&-park {/* 公園 */background: #2bf40a;}
//	&-baby {/* 赤ちゃんの駅 */background: #ff979e;}
//	&-welfare {/* 福祉施設 */background: #e633a0;}
//	&-pharmacy {/* 薬局 */background: #ff2323;}
//	&-hospital {/* 病院 */background: #b70000;}
//}
/*
//-------------------------------------------------------------
// Common     	    - 共通
//-------------------------------------------------------------
*/
.container {
  box-sizing: border-box;
	background: #fff;
  width: $base_wrap;
	&.is-top {
		margin-top: 100px;
		@include mq-down(md) {
			margin-top: 60px;
		}
	}
	&.is-contents {
		background: #f5f5f5;
		padding: 6em 0;
		@include mq-down(md) {
			padding: 1em;
		}
	}
}
.contents {
  margin: 0 auto;
  max-width: 1200px;
  min-width: 800px;
  width: 84%;
	z-index: 99;
	position: relative;
  &::after {
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
  }
  @include mq-down(md) {
    max-width: $base_wrap;
    min-width: $base_wrap;
  }
}
// 斜線のタイトル
.page-title {
	font-size: fz(18);
	text-align: center;
	position: relative;
  padding: 1.5em 1em;
	margin-bottom: 2em;
	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 10px;
		content: '';
		background-image: -webkit-repeating-linear-gradient(135deg, #000, #000 1px, transparent 2px, transparent 5px);
		background-image: repeating-linear-gradient(-45deg, #000, #000 1px, transparent 2px, transparent 5px);
		background-size: 7px 7px;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	span {
		font-size: fz(8);
		display: block;
		padding-top: .5em;
	}
	@include mq-down(sm) {
		margin-bottom: 1.5em;
	}
}
/*
//-------------------------------------------------------------
// Modal            - モーダル
//-------------------------------------------------------------
*/
.modal{
	display: none;
	height: 100vh;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999999991;
}
.modal-bg{
	background: rgba(0,0,0,0);
	height: 100vh;
	position: absolute;
	width: 100%;
	&.is-gray {
		background: rgba(0,0,0,.7);
	}
}
.modal-content{
	background: #fff;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 50%;
	line-height: 1;
	box-shadow: 0 3px 3px -1px #777;
	@include mq-down(sm){
		width: 96%;
	}
}
.modal-inner {
	position: relative;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 40px;
	line-height: 1.6;
	&.is-search {
		padding: 1.5em 6vw;
	}
	&-wrap {
		overflow-y: scroll;
		height: 100%;
	}
	@include mq-down(sm){
		padding: 50px 10px;
	}
}
.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	background: #121212;
	color: #fff;
	.icon {
		color: #fff;
		display: inline-block;
		font-size: fz(20);
		vertical-align: middle;
		text-align: center;
		position: relative;
		height: 40px;
		width: 40px;
		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
.modal-title {
	font-size: fz(18);
	margin-bottom: 1em;
	&-spot {
		font-size: fz(14);
	}
	@include mq-down(sm){
		font-size: fz(16);
		margin-bottom: .4em;
	}
}
// 印刷設定
.mp-hanrei {
	display: flex;
	justify-content: center;
	padding-bottom: 1.5em;
	li {
		padding: 0 1em;
	}
}
/*
//-------------------------------------------------------------
// Timeline         - 関係者に連絡（タイムライン）
//-------------------------------------------------------------
*/
.timeline {
	display: flex;
	&-l {
		width: 30%;
		background: $gray3;
		border-right: 1px solid $gray2;
	}
	&-r {
		width: 70%;
		border-bottom: 15px solid #fff;
	}
	@include mq-down(sm){
		flex-direction: column;
		&-l {
			order: 2;
			width: 100%;
		}
		&-r {
			order: 1;
			width: 100%;
		}
	}
}
.tl-title {
	border-bottom: 1px solid $gray2;
	font-size: fz(18);
	text-align: center;
	padding: 1em;
}
.tl-input {
	background: #f5f5f5;
	padding: .5em;
}
.tl-submit {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px 0;
	a {
		font-size: fz(11);
	}
}
.tl-date {
	h4 {
		color: #a6a6a6;
		font-size: fz(10);
		font-weight: normal;
		letter-spacing: .08em;
		position: relative;
		padding: 0 65px;
		text-align: center;
	}
	h4:before {
		position: absolute;
		top: calc(50% - 1px);
		left: 0;
		width: 100%;
		height: 1px;
		content: '';
		background: $gray3;
	}
	h4 span {
		position: relative;
		padding: 0 1em;
		background: #fff;
	}
}
.tl-post {
	padding: .5em;
	border-bottom: 1px solid $gray3;
	&-inner {
		display: flex;
		dt {
			width: 40px;
			padding-right: 10px;
		}
		dd {
			width: calc(100% - 50px);
		}
	}
	a {
		color: #0963EF;
		text-decoration: underline;
	}
	@include mq-down(sm){
		border-bottom: 1px solid $gray1;
	}
}
.tl-post-head {
	display: flex;
	justify-content: space-between;
}
.tl-user {
	display: flex;
	li:first-child {
		font-size: fz(12);
		font-weight: 500;
		padding-right: 1em;
	}
	li:last-child {
		color: #a6a6a6;
		font-size: fz(9);
		letter-spacing: .05em;
	}
}
.tl-del {
	display: flex;
	align-items: baseline;
	&_read {
		display: flex;
		align-items: center;
		padding-right: .3em;
		span {
			color: $gray;
			padding: 0 4px 0 0;
		}
		img {
			width: 30px;
		}
	}
}
.tl-btn-del {
	position: relative;
	width: 30px;
	height: 30px;
	top: -7px;
	&::after {
		display: block;
		position: absolute;
		content: '';
		background: url(#{$img_path}icon_garbage.png) no-repeat;
		background-size: cover;
		width: 30px;
		height: 30px;
		top: 0;
		left: 0;
	}
}
.tl-txt {
	font-size: fz(11);
	line-height: 1.3;
}
.tl-read {
	display: flex;
	justify-content: space-between;
	background: #f2e0e3;
	border-radius: 5px;
	padding: .5em;
	margin: 1em 0 0;
	font-size: fz(12);
	color: #70232f;
	align-items: center;
}
.tl-filelabel {
	background: #fff;
	border-radius: 30px;
	padding: 10px;
	display: inline-block;
	line-height: 1;
	img {
		vertical-align: center;
		width: 20px;
	}
}
#filesend {
	display: none;
}
.tl-user-list {
	background: #fff;
	overflow: hidden;
	overflow-y: scroll;
	min-height: 200px;
	max-height: 600px;
	li {
		border-bottom: 1px solid $gray3;
	}
	@include mq-down(sm){
		max-height: 200px;
	}
}
.tl-user-name-ti {
	background: #333;
	color: #999;
	font-size: fz(12);
	padding: .2em .5em;
}
.tl-user-name {
	display: flex;
	align-items: center;
	font-size: fz(11);
	line-height: 1.2;
	padding: .5em;
	dt {
		width: 40px;
		padding-right: 10px;
		span {
			display: inline-block;
			width: 30px;
		}
	}
	dd {
		width: calc(100% - 60px);
	}
}
/*
//-------------------------------------------------------------
// Town Walk         - 町歩き避難ルート
//-------------------------------------------------------------
*/
.tw-list {
	border-top: 1px solid $gray;
	li {
		border-bottom: 1px solid $gray;
	}
}
.tw-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	a {
		display: block;
		text-decoration: none;
		line-height: 1.4;
	}
	&_l {
		width: calc(100% - 20px);
		a {
			position: relative;
			padding: 1em 1em 1em 23px;
			&::before {
				position: absolute;
				content: '';
				width: 12px;
				height: 16px;
				top: 1.3em;
				left: 3px;
				border-right: 6px solid transparent;
				border-bottom: 12px solid $main_color;
				border-left: 6px solid transparent;
				transform: rotate(90deg);
			}
		}
		.time {
			display: block;
			color: #adadad;
			font-size: fz(12);
		}
	}
	&_r {
		width: 20px;
		height: 20px;
		a {
			display: block;
			position: relative;
			height: 20px;
			width: 20px;
			&::before {
				position: absolute;
				content: '';
				width: 20px;
				height: 20px;
				top: 0;
				background: url(#{$img_path}icon_pen.png) no-repeat;
				background-size: cover;
			}
		}
	}
}
.tw-record {
	position: fixed;
	left: 50%;
	bottom: 0;
	background: #fff;
	z-index: 99;
	padding: 1em;
	width: 60vw;
	transform: translate(-50%,0);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
	border-radius: 10px 10px 0 0;
	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	dt {
		color: #adadad;
		font-size: fz(15);
	}
	dd {
		font-size: fz(20);
		font-weight: 500;
	}
}
//登録
.tw-regist {
	background: rgba(#fff, 0.9);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 0;
	width: 340px;
	z-index: 99;
	transform: translate(-50%,-50%);
	&-inner {
		//padding: 2em;
		padding: 1em;
	}
	&-rute {
		padding-bottom: .8em;
		dt {
			display: inline-block;
			font-size: fz(12);
			padding: 0 0 .2em;
		}
	}
	@include mq-down(sm){
		width: 96%;
		top: auto;
		bottom: 0;
		transform: translate(-50%,0);
		border-radius: 10px 10px 0 0;
	}
}
.tw-title {
	border-bottom: 1px solid #333;
	padding-bottom: .5em;
	margin-bottom: 1em;
	font-size: fz(15);
	font-weight: 500;
	text-align: center;
}

/*
//-------------------------------------------------------------
// List            - 様式集・リンク集
//-------------------------------------------------------------
*/
.sec {
	padding-bottom: 3.5em;
	h2 {
		font-size: fz(18);
		padding-bottom: 1em;
	}
	h3 {
		font-size: fz(14);
		padding: 0 0 .6em 1em;
		position: relative;
		span {
			color: $main_color;
			display: inline-block;
			padding-right: .5em;
		}
	}
	ul {
		padding-bottom: 2em;
		margin-left: 1em;
		a {
			display: block;
		}
	}
	p {
		font-size: fz(12);
		padding-left: 1em;
	}
	@include mq-down(sm){
		padding-bottom: 2em;
		h2 {
			font-size: fz(16);
		}
	}
}
.link-list {
	padding-left: 1em;
	dt {
		font-size: fz(14);
		padding-bottom: .2em;
	}
	dd {
		color: #666;
		font-size: fz(12);
		line-height: 1.4;
		padding-bottom: 1em;
	}
}
/*
//-------------------------------------------------------------
// User            - ユーザー情報の変更
//-------------------------------------------------------------
*/
.user-contents {
	display: flex;
	justify-content: space-between;
	aside {
		width: 25%;
	}
	main {
		width: 68%
	}
	&.is-top {
		justify-content: center;
	}
	@include mq-down(md){
		flex-direction: column;
		aside,main {
			width: 100%;
		}
		aside {
			order: 2;
		}
		main {
			order: 1;
			margin-bottom: 3em;
		}
	}
}
.uc-title {
	font-size: fz(18);
	padding-bottom: 1.5em;
}
.uc-txt {
	font-size: fz(12);
	line-height: 1.45;
	padding-bottom: 1em;
}
.uc-nav {
	h2 {
		font-size: fz(14);
		border-bottom: 4px solid #111;
		padding-bottom: .8em;
		margin-bottom: 1em;
	}
	li {
		border-bottom: 1px solid $gray2;
		a {
			display: block;
			font-size: fz(12);
			padding: .65em 0;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.uc-box {
	padding-bottom: 1em;
	a {
		text-decoration: none;
		display: block;
		position: relative;
		padding: 1em 2.3em 1em 1em;
		&::after {
			content: '';
			position: absolute;
			width: 11px;
			height: 11px;
			border-top: 2px solid rgba(#000,.92);
			border-right: 2px solid rgba(#000,.92);
			top: 50%;
			right: 1.2em;
			transform: rotate(45deg);
			transition: .2s;
		}
		&:hover {
			opacity: .65;
		}
		&:hover::after {
			right: .6em;
		}
	}
	@include mq-down(sm){
		a {
			padding: .5em 1.5em .5em 0;
			&::after {
				right: 5px;
			}
			&:hover::after {
				right: 5px;
			}
		}
	}
}
.uc-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	li:first-child {
		width: 100px;
	}
	li:last-child {
		width: calc(100% - 120px);
	}
	dt {
		font-size: fz(14);
		font-weight: 500;
	}
	dd {
		color: #666;
		font-size: fz(12);
		line-height: 1.4;
	}
	@include mq-down(sm){
		li:first-child {
			width: 50px;
		}
		li:last-child {
			width: calc(100% - 70px);
		}
		dd {
			font-size: fz(10);
		}
	}
}
/*
//-------------------------------------------------------------
// Page top       	- ページの先頭にもどる
//-------------------------------------------------------------
*/
@include mq-up(sm){
	.pagetop {
		position:fixed;
		z-index:100;
		right:30px;
		bottom:30px;
		display:none;
		a {
			display: block;
			position: relative;
			width: 50px;
			height: 50px;
			background: $main_color;
			border-radius: 50%;
			text-indent: -9999px;
			overflow: hidden;
			opacity: 0.8;
			&:hover {
				background:#333;
			}
			&::before {
				content:"";
				margin:auto;
				position:absolute;
				top:5px;
				bottom:0;
				left:0;
				right:0;
				width:11px;
				height:11px;
				border-top:3px solid #FFF;
				border-right:3px solid #FFF;
				-webkit-transform:rotate(-45deg);
				transform:rotate(-45deg);
			}
		}
	}
}
@include mq-down(sm){
	.pagetop {
		a {
			display:block;
			background: $main_color;
			color: #fff;
			padding: 1.8em 0;
			position: relative;
			text-align: center;
			text-decoration: none;
			&::before {
				content:"";
				margin:auto;
				position:absolute;
				top:5px;
				bottom:0;
				left:0;
				right:0;
				width:11px;
				height:11px;
				border-top:3px solid #FFF;
				border-right:3px solid #FFF;
				-webkit-transform:rotate(-45deg);
				transform:rotate(-45deg);
			}
		}
	}
}
/*
//-------------------------------------------------------------
// Table            - テーブル
//-------------------------------------------------------------
*/
table {
	width: 100%;
}
.c-tbl {
	border-top: 4px solid #000;
	th,td {
		border-top: 1px solid #111;
		border-bottom: 1px solid #111;
		padding: 1em;
		vertical-align: baseline;
	}
	th {
		background: $gray3;
		font-weight: 500;
		text-align: center;
	}
	td {
		background: #fff;
	}
	@include mq-down(sm) {
		th,td {
			padding: .5em;
		}
	}
	a {
		text-decoration: none;
		color: #0c5ff8;
		&:hover {
			color: #083b97;
		}
	}
}
/*
//-------------------------------------------------------------
// Parts            - 各パーツ
//-------------------------------------------------------------
*/
// ■丸リスト
//-------------------------------------------------------
.p-circle-list {
  padding: 0;
  li {
    line-height: 1.4;
    padding: 0 0 .8em 1.4em;
    position: relative;
    &::before {
      background: $main_color;
      border-radius: 6px;
      bottom: 0;
      content: "";
      height: 7px;
      left: .3em;
      position: absolute;
      top: .55em;
      width: 7px;
    }
    &:last-child{
      padding-bottom: 0;
    }
  }
}
// ■番号付きリスト
//-------------------------------------------------------
.p-num-list {
  counter-reset: my-counter;
  list-style: none;
  padding: 1em 0 0 .5em;
  li {
		line-height: 1.4;
    margin-bottom: 1em;
    padding: 0 0 .8em 1.4em;
    position: relative;
    &:last-child {
      margin: 0;
    }
    &:before {
      content: counter(my-counter);
      counter-increment: my-counter;
      display: block;
      text-align: center;
			left: -.3em;
      position: absolute;
    }
  }
  @include mq-down(sm){
    padding: 1em 0 0 1em;
		li {
			padding: 0 0 .2em 1.4em;
		}
  }
}
//　DL の横並びリスト
//------------------------------------------
.dl-list {
	display: flex;
	width: 100%;
	dt {
		font-weight: 500;
		padding: 1em;
		width: 30%;
		@include mq-down(sm){
			display: block;
			width: auto;
		}
	}
	dd {
		padding: 1em;
		width: 70%;
		@include mq-down(sm){
			display: block;
			padding: 1em;
		}
	}
}
.dl-list {
	&.is-login {
		dt {
			width: 35%;
			background: none;
		}
		dd {
			width: 65%;
		}
		@include mq-down(md){
			flex-direction: column;
			padding-bottom: .5em;
			dt,dd {
				width: 100%;
			}
			dt {
				padding: 0;
				font-size: fz(11);
			}
			dd {
				padding: 0 .4em;
			}
		}
	}
}
.dl-list {
	&.is-modal {
		border-bottom: 1px solid $gray;
		&.is-first {
			border-top: 1px solid $gray;
		}
		dt {
			background: #f8f8f8;
			padding: .4em .6em;
			width: 38%;
		}
		dd {
			padding: .4em .6em;
			width: 62%;
		}
		@include mq-down(sm){
			display: block;
			border: none;
			&.is-first {
				border: none;
			}
			dt,dd {
				padding: .5em;
			}
			dt {
				display: block;
				width: auto;		
				padding: .3em .5em;
				font-size: fz(10);
			}
			dd {
				display: block;
				width: 100%;
				font-size: fz(12);
			}
		}
	}
}
.dl-list {
	&.is-modal-spot {
		display: flex;
		border-bottom: 1px solid $gray;
		padding: .25em 0;
		dt {
			color: #b3b3b3;
			font-size: fz(11);
			padding: 0;
			width: 30%;		
		}
		dd {
			padding: 0;
			width: 70%;
		}
		@include mq-down(sm){
			line-height: 1.25;
			dt {
				font-size: fz(10);
			}
		}	
	}
}
.dl-list.is-user {
	&.is-first {
		border-top: 1px solid $gray2;
	}
	dt {
		background: $gray3;
		border-bottom: 1px solid $gray2;
		width: 40%;
	}
	dd {
		border-bottom: 1px solid $gray2;
		width: 60%;
	}
	@include mq-down(sm){
		flex-direction: column;
		dt,dd {
			border: none;
			width: 100%;
		}
		dt {
			padding: .2em .6em;
		}
	}
}
//
.hidden-pc {
	display: none;
	@include mq-down(md) {
		display: block;
	}
}
.txt-c {
	text-align: center;
}
.txt-justify {
	text-align: justify;
}
.fs-sm {
	font-size: fz(11);
}
// padding , margin
// セクションや項目の下を空ける
//---------------------------------
.pb-xs {
  padding: 0 0 1em;
}
.pb-sm {
  padding: 0 0 2em;
  @include mq-down(sm) {
    padding: 0 0 1.5em;
  }
}
.pb-md {
  padding: 0 0 4em;
  @include mq-down(sm) {
    padding: 0 0 2em;
  }
}
.pb-lg {
  padding: 0 0 6em;
  @include mq-down(sm) {
    padding: 0 0 3em;
  }
}
.mb-xs {
  margin-bottom: 1em;
  @include mq-down(sm) {
    margin-bottom: .5em;
  }
}
.mb-md {
  margin-bottom: 4em;
  @include mq-down(sm) {
    margin-bottom: 2em;
  }
}
.mb-lg {
  margin-bottom: 6em;
  @include mq-down(sm) {
    margin-bottom: 3em;
  }
}