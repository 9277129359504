@charset "utf-8";
/*
// ===================================================================
// Popup
// ===================================================================
*/
.leaflet-container {
	font-size: fz(12);
	font-family: $base_font;
}
.leaflet-container a.leaflet-popup-close-button {
	width: 38px;
	height: 38px;
	font:24px/24px Tahoma, Verdana, sans-serif;
	color: #fff;
	background: #000;
	padding: 4px 0 0;
}
.leaflet-popup-content {
	margin: 40px 20px 20px;
	width: calc(100% - 40px)!important;
	@include mq-down(sm) {
		margin: 0;
		width: 100%!important;
		padding: 10px;
		box-sizing: border-box;
	}
}
.leaflet-popup-content-wrapper {
	border-radius: 0;
	width: 50vw;
	@include mq-down(sm) {
		width: 92vw;
	}
}
.leaflet-control-layers-base {
	position: relative;
	z-index: 9999;
}
/* 地図レイヤーのアイコンの位置 */
.leaflet-right {
	right: 54px;
}
/* 地図の+-ボタンを強制的に右寄せ */
.leaflet-bottom {
	right: 0!important;
}