.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 地図レイヤーのアイコンの位置 */
.leaflet-right {
  right: 54px !important;
}

/* 地図の+-ボタンを強制的に右寄せ */
.leaflet-bottom {
  right: 0 !important;
  /* margin-bottom: 50 !important; */
}

.leaflet-right .leaflet-control {
  margin-bottom: 0 !important;
}

.leaflet-container {
  font-size: 93% !important;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif !important;
}

.leaflet-container a.leaflet-popup-close-button {
  width: 38px !important;
  height: 38px !important;
  font: 24px/24px Tahoma, Verdana, sans-serif !important;
  color: #fff !important;
  background: #000 !important;
  padding: 4px 0 0 !important;
}

.leaflet-popup-content {
  margin: 40px 20px !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 0 !important;
}

#small-map {
  width: 100%;
  height: 300px;
}

#chat-map {
  width: 100%;
  height: 500px;
}

.container.is-about {
  background: #EBEBEB;
  padding: 4em 0 6em;
 }
 @media screen and (max-width: 959px) {
  .container.is-about {
   padding: 1em;
  }
 }
 .about-txt {
  text-align: justify;
 }
 @media screen and (max-width: 959px) {
  .about-txt {
   line-height: 1.55;
   font-size: 85%;
   padding: 0 0 1.5em;
  }
 }
