@charset "utf-8";

//=============================================================
//	リセット
//=============================================================

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display: block;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

th, td {
	vertical-align: middle;
}

input, select {
	vertical-align: middle;
	outline: none;
}

img {
	border: 0;
	vertical-align: bottom;
	max-width: 100%;
	height: auto;
}
