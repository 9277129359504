@charset "utf-8";

//=============================================================
//	メディアクエリ    @include media(mobile) {}
//=============================================================
// 変数の定義

// min-width
$breakpoint-up: (
  'sm': 'screen and (min-width: 600px)',
  'md': 'screen and (min-width: 960px)',
  'lg': 'screen and (min-width: 1200px)',
  'xl': 'screen and (min-width: 1201px)',
) !default;

// max-width
$breakpoint-down: (
  'sm': 'screen and (max-width: 599px)',
  'md': 'screen and (max-width: 959px)',
  'lg': 'screen and (max-width: 1179px)',
) !default;
// @mixinの定義
@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}
@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

//=============================================================
//　floatの親につける　@include clearfix;
//=============================================================
@mixin clearfix {
	content: "";
	display: block;
	clear: both;
}
