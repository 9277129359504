@charset "utf-8";
.u-hidden_pc {
	display: none;
	@include mq-down(sm) {
		display: block;
	}
}
.u-hidden_sp {
	@include mq-down(sm) {
		display: none;
	}
}
// テキスト
.u-bold {
	font-weight: 500;
}
.u-txt-l {
	text-align: left;
}
.u-txt-c {
	text-align: center;
}
.u-txt-r {
	text-align: right;
}
//　均等配置
//.u-txt-justify {
//	line-height: 2;
//	text-align: justify;
//	text-justify: inter-ideograph;
//}
//　背景色
//.u-bgc-white {
//	background: #fff;
//}
//.u-bgc-pdf {
//	background: #ED1919;
//}
//.u-bgc-word {
//	background: #3570E9;
//}
//.u-bgc-excel {
//	background: #2FB270;
//}
//.u-label {
//	color: #fff;
//}
// padding , margin
// セクションや項目の下を空ける
//---------------------------------
.u-pb-xs {
	padding-bottom: 1em;
}
.u-pb-sm {
	padding-bottom: 2em;
	@include mq-down(sm) {
		padding-bottom: 1.5em;
	}
}
.u-pb-md {
	padding-bottom: 4em;
	@include mq-down(sm) {
		padding-bottom: 2em;
	}
}
.u-pb-lg {
	padding-bottom: 6em;
	@include mq-down(sm) {
		padding-bottom: 3em;
	}
}
.u-mb-xs {
margin-bottom: 1em;
}
.u-mb-sm {
margin-bottom: 2em;
	@include mq-down(sm) {
		margin-bottom: 1.5em;
	}
}
.u-mb-md {
margin-bottom: 4em;
	@include mq-down(sm) {
		margin-bottom: 2em;
	}
}
.u-mb-lg {
margin-bottom: 6em;
	@include mq-down(sm) {
		margin-bottom: 3em;
	}
}
