@charset "utf-8";

//=============================================================
//	共通設定
//=============================================================

html {
	box-sizing: border-box;
	overflow-y: scroll;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
	font-family: $base_font;
	text-align: left;
	font-size: 16px;
	line-height: 1.8em;
	color: $font_color;
	background: #f1f1f1;
	-webkit-text-size-adjust: 100%;
	overflow: hidden;
	// 長い英単語を途中で折り返しを制御する
	word-wrap: break-word;
	overflow-wrap: break-word;
//	@include mq-down(sm) {
//		font-size: 13px;
//	}
}

//input,
//textarea {
//	font-family: $base_font;
//	-webkit-appearance: none;
//	-moz-appearance: none;
//	appearance: none;
//}

select {
	font-family: $base_font;
}

a:link,
a:visited {
	color: $link_color;
	text-decoration: underline;
}

a:hover,
a:active {
	color: $link_color;
	text-decoration: none;
}

h1,h2,h3,h4,h5 {
	font-weight: 500;
}

// ===================================================================
// フォントサイズ
// ===================================================================
$fontSizeList: 62%, 70%, 77%, 85%, 93%, 100%, 108%, 116%, 124%, 131%, 139%, 147%, 154%, 162%, 170%, 177%, 185%, 193%, 200%, 208%, 216%, 224%, 231%, 239%, 247%, 254%, 262%, 270%, 277%, 285%, 293%, 300%, 308%, 316%, 324%, 331%, 339%, 347%, 354%, 362%, 370%, 377%, 385%, 393%, 400%;

@function fz($size) {
	@if $size < 8 {
		$size: 8;
	}
	@if $size > 50 {
		$size: 50;
	}
	@return nth($fontSizeList, $size - 7);
}
