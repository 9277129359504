@charset "utf-8";
// ===================================================================
// 印刷用
// ===================================================================
.p-print-ttl {
	display: none;
}
@media print {
	header,.leaflet-control-layers,.hanrei-shinsui,.modal-content,
	.leaflet-control-zoom,.spot-regist-pre,.l-map-btn--search,.l-map-btn--here,.l-map-btn--reload,.my-leaflet-control {
		display: none;
	}
	.hanrei {
		max-width: 100%;
		bottom: 0;
		left: 0;
	}
	* html body {
		zoom: .7;
	}
	.p-print-ttl {
		-webkit-print-color-adjust: exact;	//背景色を印刷画面に反映させるため
		background: black;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
		h1 {
			color: #ffde02;
			font-size: fz(18);
			padding: .65em 1em;
		}
	}
}