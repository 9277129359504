@charset "utf-8";
/*
// ===================================================================
// Form
// ===================================================================
*/
//　input要素がiPhoneなどのiOsデバイスで勝手に角丸になるのを防ぐ
.leaflet-popup {
	input[type="submit"] {
		-webkit-appearance:none;
		appearance: none;
		border: none;
		cursor: pointer;
		font-size: inherit;
	}	
	input[type="text"],input[type="email"],input[type="password"],textarea {
		-webkit-appearance:none;
		appearance: none;
		border: 1px solid $gray;
		box-sizing: border-box;
		font-size: fz(13);
		//margin-bottom: .5em;
		padding: .4em;
		outline: none;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%;
		&:focus {
			box-shadow: 0 0 2px rgba(0,0,0,0.15);
			border: 1px solid $main_color;
		}
		@include mq-down(sm){
			font-size: 16px;
			padding: .15em;
			width: 80%;
		}
	}
	textarea {
		width: 100%;
		@include mq-down(sm){
			line-height: 1.35;
		}
	}
}
.search-location {
	display: flex;
	justify-content: space-between;
	margin: 1em 0;
	li {
		&:first-child {
			width: calc(100% - 100px);
		}
		&:last-child {
			width: 100px;
			background: #111;
		}
	}
	.c-btn-search {
		box-sizing: border-box;
		background: #111;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		letter-spacing: 1px;
		text-decoration: none;
		text-align: center;
		font-size: fz(13);
		padding: 13px 0;
		width: 100%;
		height: 50px;
	}
	input[type="text"] {
		-webkit-appearance:none;
		appearance: none;
		border: 1px solid $gray;
		box-sizing: border-box;
		display: block;
		font-size: fz(13);
		padding: .4em;
		outline: none;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%;
		height: 50px;
		&:focus {
			//box-shadow: 0 0 2px rgba(0,0,0,0.15);
			border: 1px solid $main_color;
		}
		@include mq-down(sm){
			font-size: 16px;
			padding: 1em;
		}
	}
	@include mq-down(sm){
		padding: 1em;
		li {
			&:first-child {
				width: calc(100% - 78px);
			}
			&:last-child {
				width: 70px;
				background: #111;
			}
		}
	}
}
.gnav {
	input[type="submit"] {
		-webkit-appearance:none;
		appearance: none;
		border: none;
		cursor: pointer;
		font-size: inherit;
	}
	input[type="text"],input[type="email"],input[type="password"],textarea {
		border: 1px solid $gray;
		box-sizing: border-box;
		font-size: fz(14);
		margin-bottom: .5em;
		padding: .5em;
		outline: none;
		-webkit-transition: all .3s;
		transition: all .3s;
		&:focus {
			box-shadow: 0 0 2px rgba(0,0,0,0.15);
			border: 1px solid $main_color;
		}
	}
	textarea {
		width: 100%;
	}
	//
	
	//　セレクトボックス
	//------------------------------------------
	.f-select {
		-moz-appearance: none;
		background: url(#{$img_path}icon_select_arrow.png) no-repeat right 10px center;
		border: 1px solid #ccc;
		border-radius: 0;
		padding: .2em 1.5em .2em .2em;
		position: relative;
	}
	select{
		outline: none;
		-moz-appearance: none;
		text-overflow: '';
		background: none transparent;
		vertical-align: middle;
		font-size: inherit;
		color: inherit;
		-webkit-appearance: button;
		-moz-appearance: button;
		appearance: button;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		padding: .2em;
		border: none;
		width:100%;
		@include mq-down(sm){
			font-size: 16px; // 選択するときの拡大防止
		}
	}
	select::-ms-expand {
		display: none;
	}
	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #828c9a;
	}

	//　ラジオボタン
	//------------------------------------------
	.f-radio input[type="radio"] {
		display: none;
		&:checked + label::before {
			background: #fff;
			border: 1px solid $gray;
		}
		&:checked + label::after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
	.f-radio label {
		position: relative;
		display: inline-block;
		padding: 0 10px 5px 20px;
		cursor: pointer;
		&::before,&::after {
			position: absolute;
			content: '';
			top: 50%;
			border-radius: 100%;
			-webkit-transition: all .2s;
			transition: all .2s;
		}
		&::before {
			left: 0;
			width: 14px;
			height: 14px;
			margin-top: -8px;
			background: #f3f3f3;
			border: 1px solid $gray;
		}
		&:hover::before {
			background: #fff;
		}
		// 選択したときの色
		&::after {
			opacity: 0;
			left: 3px;
			width: 8px;
			height: 8px;
			margin-top: -5px;
			background: $main_color;
			-webkit-transform: scale(2);
			transform: scale(2);
		}
	}
	//　チェックボックス
	//------------------------------------------
	.f-check input[type="checkbox"] {
		display: none;
		&:checked + label::before {
			background: #fff;
			//　選択したときの色
			border: 1px solid $gray;
		}
		&:checked + label::after {
			opacity: 1;
			-webkit-transform: rotate(-45deg) scale(1);
			transform: rotate(-45deg) scale(1);
		}
	}
	.f-check label {
		position: relative;
		display: flex;
		line-height: 1.45;
		padding: 2px 20px 5px 26px;
		cursor: pointer;
		-webkit-transition: all .2s;
		transition: all .2s;
		&::before,
		&::after {
			position: absolute;
			content: '';
			-webkit-transition: all .2s;
			transition: all .2s;
		}
		&::before {
			top: 13px;
			left: 0;
			width: 18px;
			height: 18px;
			margin-top: -8px;
			background: #f4f4f4;
			border: 1px solid $gray;
			border-radius: 3px;
		}
		&::after {
			opacity: 0;
			top: 14px;
			left: 3px;
			width: 12px;
			height: 6px;
			margin-top: -4px;
			border-left: 2px solid $accent_color;
			border-bottom: 2px solid $accent_color;
			-webkit-transform: rotate(-45deg) scale(.5);
			transform: rotate(-45deg) scale(.5);
		}
		&:hover::before {
			background: #fff;
		}
	}
	.f-check:hover {
		color: $main_color;
	}
}
// ログイン
.login-form,.tw-regist {
input[type="text"],input[type="email"],input[type="password"],textarea {
		-webkit-appearance: none;
		padding: 1em;
		border: 1px solid $gray;
		&:focus {
			//box-shadow: 0 0 2px rgba(0,0,0,0.15);
			border: 1px solid $main_color;
		}
		@include mq-down(sm){
			font-size: 16px;
			padding: .5em;
			//width: 80%;
		}
	}
}

// 通常のラジオ
//　ラジオボタン
//------------------------------------------
.f-radio input[type="radio"] {
	display: none;
	&:checked + label::before {
		background: #fff;
		border: 1px solid $gray;
	}
	&:checked + label::after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.f-radio label {
	position: relative;
	display: inline-block;
	padding: 0 10px 5px 28px;
	cursor: pointer;
	&::before,&::after {
		position: absolute;
		content: '';
		top: 50%;
		border-radius: 100%;
		-webkit-transition: all .2s;
		transition: all .2s;
	}
	&::before {
		left: 0;
		width: 20px;
		height: 20px;
		margin-top: -12px;
		background: #f3f3f3;
		border: 1px solid $gray;
	}
	&:hover::before {
		background: #fff;
	}
	// 選択したときの色
	&::after {
		opacity: 0;
		left: 4px;
		width: 12px;
		height: 12px;
		margin-top: -9px;
		background: $accent_color;
		-webkit-transform: scale(2);
		transform: scale(2);
	}
}
.timeline {
	textarea {
		-webkit-appearance:none;
		appearance: none;
		border: 2px solid $gray;
		box-sizing: border-box;
		font-size: fz(13);
		outline: none;
		-webkit-transition: all .3s;
		transition: all .3s;	

		width: 100%;
		height: calc( 1.35em * 5.75 );
		line-height: 1.35;
		padding: .5em;
		&:focus {
			box-shadow: 0 0 2px rgba(0,0,0,0.15);
			border: 2px solid $main_color;
		}
		@include mq-down(sm){
			line-height: 1.35;
			font-size: 16px;
		}
	}
}
// ユーザー情報の変更（mypage）
.user-form {
	input[type="text"],input[type="email"],input[type="password"],textarea {
		-webkit-appearance:none;
		appearance: none;
		border: 1px solid $gray;
		box-sizing: border-box;
		font-size: fz(13);
		//margin-bottom: .5em;
		padding: .4em;
		outline: none;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%;
		&:focus {
			box-shadow: 0 0 2px rgba(0,0,0,0.15);
			border: 1px solid $main_color;
		}
		@include mq-down(sm){
		}
	}
	//　セレクトボックス
	//------------------------------------------
	.f-select {
		-moz-appearance: none;
		background: url(#{$img_path}icon_select_arrow.png) no-repeat right 10px center;
		border: 1px solid #ccc;
		border-radius: 0;
		padding: .2em 1.5em .2em .2em;
		position: relative;
	}
	select{
		outline: none;
		-moz-appearance: none;
		text-overflow: '';
		background: none transparent;
		vertical-align: middle;
		font-size: inherit;
		color: inherit;
		-webkit-appearance: button;
		-moz-appearance: button;
		appearance: button;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		padding: .2em;
		border: none;
		width:100%;
		@include mq-down(sm){
			font-size: 16px; // 選択するときの拡大防止
		}
	}
	select::-ms-expand {
		display: none;
	}
	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #828c9a;
	}
}
// inputの長さ
//------------------------------------------
.f-w-xs {
	width: 3em;
	@include mq-down(sm){
		width: 100%;
	}
}
.f-w-sm {
	width: 7em;
	@include mq-down(sm){
		width: 100%;
	}
}
.f-w-md {
	width: 18em;
	@include mq-down(sm){
		width: 100%;
	}
}
.f-w-lg {
	width: 100%;
}
// エラー表示
//------------------------------------------
.f-error {
	display: inline-block;
	padding: .1em;
	border-radius: 5px;
	color: #ff3737;
	@include mq-down(sm){
		font-size: fz(11);
	}
}
// 必須ラベル
//------------------------------------------
.c-label {
	background: #666;
	border-radius: 3px;
	color: white;
	display: inline-block;
	font-weight: normal;
	font-size: fz(10);
	float: right;
	letter-spacing: .1em;
	padding: 0 0.8em;
	margin: 0 .5em;
}
// フォームのボタン
//------------------------------------------
.submit-btn {
	display: flex;
	justify-content: center;
	li {
		margin: 0 1em 1em;
		width: 150px;
	}
}
.popup-btn {
	margin-top: 1em;
	text-align: center;
}
/*
//-------------------------------------------------------------
//　Button
//-------------------------------------------------------------
*/
//-------------------------------------------------------------
//　1 クラスをつける
//-------------------------------------------------------------
a.c-btn,.c-btn {
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
	cursor: pointer;
  display: inline-block;
  letter-spacing: 1px;
  //padding: 1em inherit;
  text-decoration: none;
  text-align: center;
  transition: all .3s ease 0s;
	font-size: fz(13);
}
//-------------------------------------------------------------
// 2 ボタンの種類を選ぶ
//-------------------------------------------------------------
a.c-btn-default,.c-btn-default {
  border: 1px solid #111;
  background: #111;
  color: #fff;
  &:hover,&:focus,&:active {
    color: $main_color;
    background: #000;
  }
	&:visited {
		color: #fff;
	}
}
a.c-btn-solid,.c-btn-solid {
  border: 1px solid #000;
  background: #000;
	border-radius: 4px;
	color: #fff;
	font-size: fz(15);
  //display: block;
  &:hover,&:focus,&:active {
    background: rgba($accent_color, .8);
    border-color: $accent_color;
		color: #fff;
  }
  &:visited {
    color: #fff;
  }
}
a.c-btn-gray,.c-btn-gray {
  border: 1px solid #999;
  background: #999;
	border-radius: 4px;
	color: #fff;
	font-size: fz(15);
  //display: block;
  &:hover,&:focus,&:active {
    background: rgba($accent_color, .8);
    border-color: $accent_color;
		color: #fff;
  }
  &:visited {
    color: #fff;
  }
}
a.c-btn-green,.c-btn-green {
  border: 1px solid #38c578;
  background: #38c578;
	border-radius: 4px;
	color: #fff;
	font-size: fz(15);
  //display: block;
  &:hover,&:focus,&:active {
    background: rgba(#38c578, .8);
    border-color: #38c578;
		color: #fff;
  }
  &:visited {
    color: #fff;
  }
}
a.c-btn-red,.c-btn-red {
  border: 1px solid $accent_color;
  background: $accent_color;
	border-radius: 10px;
	color: #fff;
	font-size: fz(15);
	font-weight: bold;
  //display: block;
  &:hover,&:focus,&:active {
    background: rgba($accent_color, .8);
    border-color: $accent_color;
		color: #fff;
  }
  &:visited {
    color: #fff;
  }
}
a.c-btn-line,.c-btn-line {
  border: 1px solid #fff;
  color: #fff;
	font-size: fz(15);
  text-align: left;
	&:link {
		color: #fff;
	}
  &:hover,&:focus,&:active {
    background: rgba($main_color, .8);
    border-color: $main_color;
  }
	&:hover {
	//	color: #000;
	}
  &:visited {
    color: #fff;
  }
	@include mq-down(md) {
		text-align: center;
	}
}
a.c-btn-spot {
	border-radius: 5px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .65);
	color: #fff;
	padding: .4em 1em;
	width: 100%;
	background: #404040;
	background: -moz-linear-gradient(top,  #595959 0%, #404040 100%);
	background: -webkit-linear-gradient(top,  #595959 0%,#404040 100%);
	&:link,&:hover,&:visited {
		color: #fff;
	}
	&:hover {
		background: -moz-linear-gradient(top,  #454545 0%, #353535 100%);
		background: -webkit-linear-gradient(top,  #454545 0%,#353535 100%);	
	}
}
.c-btn-tl-confirm {
	color: #fff;
	border-radius: 3px;
	padding: .3em 1em;
	background: $accent_color;
}
.c-btn-tl-spot {
	background: #fff;
	border-radius: 30px;
	padding: 10px;
	display: inline-block;
	line-height: 1;
	margin-right: .1em;
	img {
		vertical-align: center;
		width: 20px;
	}
}
.c-btn-icon {
    position: relative;
    padding-left: 2em;
    &::before {
        content: '';
        position: absolute;
        width: 1.4em;
        height: 1.4em;
        background: url(#{$img_path}icon_circle-play-solid.svg) no-repeat;
        top: 1px;
        left: 0;
    }
}
//-------------------------------------------------------------
// 3 ボタンサイズを選ぶ
//-------------------------------------------------------------
a.c-btn-fixed,.c-btn-fixed {
	box-sizing: border-box;
  padding: 1em;
	width: 100%;
  @include mq-down(sm) {
		font-size: fz(12);
		line-height: 1.2;
    padding: .85em 1em;
    width: 100%;
  }
}
.c-btn-xs {
	font-size: fz(13);
  padding: .5em 2em;
  @include mq-down(sm) {
		font-size: fz(12);
    padding: .2em 1em;
    width: 100%;
  }
}
.c-btn-sm {
  padding: .5em 2em;
  @include mq-down(sm) {
    padding: .5em 1em;
    width: 100%;
  }
}
.c-btn-md {
  padding: 1em 1.5em;
  @include mq-down(md) {
    padding: 1em;
    width: 100%;
  }
}
.c-btn-lg {
  font-size: fz(16);
  margin: 0 auto;
  padding: 1em;
  width: 300px;
  @include mq-down(md) {
    padding: .5em 1em;
    width: 100%;
  }
}
.c-btn-xl {
	box-sizing: border-box;
  font-size: fz(16);
  margin: 0 auto;
  padding: 1.4em 1em;
  width: 100%;
  @include mq-down(md) {
    padding: .5em 1em;
    width: 100%;
  }
}

.submit-side {
	text-align: center;
	padding: 10px 0 0;
	li {
		display: inline-block;
		&:first-child {
			padding-right: 10px;
		}
	}
	@include mq-down(sm) {
		&.is-regist {
			li:first-child {
				display: none;
			}
		}
	}
}
.submit-user {
	display: flex;
	justify-content: center;
	margin: 2em 0;
	li {
		padding: 0 .5em;
	}
	@include mq-down(sm) {
		flex-direction: column;
		li {
			padding: 0 0 1em;
		}
	}
}