@charset "utf-8";
//=============================================================
//　PCオンリー　ページ全体の横幅
//=============================================================
$base_wrap:          100%;
$base_width:         1200px;

//=============================================================
// 画像のパス
//=============================================================
$img_path: "../img/";
// background: url(#{$img_path}xxxx.png);

//---------------------------------------------------
//　フォント設定
//---------------------------------------------------
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500');
$base_font:"Noto Sans JP","游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",Sans-Serif;
$base_font_ie:"ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;
$base_font_min:"Times New Roman","游明朝体","Yu Mincho","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HGS明朝B","Hiragino Mincho ProN","ＭＳ Ｐ明朝","MS PMincho",serif;

//---------------------------------------------------
//　カラー設定
//---------------------------------------------------
// ベースカラー　70%
$base_color:          #f1f1f1;

// メインカラー 20%
$main_color:          #ffde02;

// アクセントカラー 10%
$accent_color:        #f22d3a;

// テキストカラー
$font_color:          #333;

$mypage-h: #999;
// リンクカラー
$link_color:          #000;
$link_color_visited:  #000;
$link_color_hover:    #000;
$link_color_active:   #000;

// フォームのチェック、ラジオボタンとかの選択状態
$form_input_color:    #3498db;
$form_active_color:   #3498db;
$form_line_color:     #aaa;

// グレー系 濃 → 薄
$gray:               	#ccc;
$gray1:              	#d9d9d9;
$gray2:              	#ebebeb;
$gray3:              	#f1f1f1;
